import { constants } from '@wsb/guac-widget-core';
import { COMMON_BUTTON_CONFIG } from '../../common/constants';

const { colorPackCategories, buttons } = constants;
const { LIGHT, LIGHT_ALT, LIGHT_COLORFUL, DARK, DARK_ALT, DARK_COLORFUL, COLORFUL } =
  constants.paintJobs;

const id = 'layout23';
const defaultPaintJob = LIGHT;

export default {
  id,
  name: 'script',
  packs: {
    color: '#5F0B35',
    font: 'yellowtail'
  },
  logo: {
    font: 'alternate'
  },
  packCategories: {
    color: colorPackCategories.NEUTRAL
  },
  headerProperties: {
    alignmentOption: 'center',
    defaultLogoAlign: 'center'
  },
  paintJobs: [LIGHT, LIGHT_ALT, LIGHT_COLORFUL, COLORFUL, DARK_COLORFUL, DARK_ALT, DARK],
  defaultPaintJob,
  applyDefaultPaintJob: true,
  buttons: {
    primary: {
      fill: buttons.fills.GHOST,
      shape: buttons.shapes.SQUARE,
      decoration: buttons.decorations.NONE,
      shadow: buttons.shadows.NONE,
      color: buttons.colors.PRIMARY
    },
    secondary: {
      fill: buttons.fills.GHOST,
      decoration: buttons.decorations.NONE,
      shadow: buttons.shadows.NONE,
      color: buttons.colors.PRIMARY
    },
    ...COMMON_BUTTON_CONFIG
  }
};
