import React from 'react';
import { omit, clamp } from 'lodash';
import { UX2, constants } from '@wsb/guac-widget-core';
import Default from '../../../common/Themes/Default/Theme';
import { resolveFontSizeCharCount } from '../../../common/utils';
import { getMenuHeadingStyle } from '../../../common/utils/legacyOverrides';
import { minimalIconMapping } from '../../../common/Components/Internal/Header/utils/mapPropsToSocialProps';
import * as socialIconPack from '../../../common/IconPacks/SocialIcons/minimalSocialIconPack';
import { Ripple } from '../../../common/loaders';
import { levelFilter } from '../../../common/utils/text';
import * as utils from '../../../common/Themes/Default/Dials/Colors/utils';
import themeConfig from '../themeConfig';

const { categoryTypes, sectionTypes } = constants;
const { PRIMARY, NEUTRAL } = categoryTypes;
const { ALT } = sectionTypes;

// H2's in this theme are absolutely positioned and rotated 10 degrees
// These widgetTypes/widgetLayouts get the special styles applied
const specialHeadingWidgetTypes = ['CALENDAR', 'CONTACT'];
const specialHeadingWidgetLayouts = ['content1', 'content2', 'content3', 'content6', 'about5'];
const specialHeadingExcludedLayouts = ['contact10'];

const typographyShared1 = {
  style: {
    font: 'alternate',
    color: 'highContrast',
    fontSize: 'xlarge',
    fontWeight: 'bold',
    letterSpacing: '0.214em',
    textTransform: 'uppercase'
  }
};

class Theme23 extends Default {
  static config = themeConfig;

  static get displayName() {
    return 'Theme23';
  }

  static excludedProps = Default.excludedProps.concat('phone');

  static getMutatorDefaultProps(widgetType, widgetPreset) {
    const defaultProps = super.getMutatorDefaultProps(widgetType, widgetPreset);

    return widgetType === 'HEADER'
      ? {
        ...defaultProps,
        useSocialLinks: true,
        useTextBackground: true,
        usePhone: false,
        useCropBackground: true,
        hasLogoAlign: true,
        headerTreatmentsConfig: {},
        coverImagePivot: {}
      }
      : defaultProps;
  }

  static getWidgetDefaultProps(widgetType, widgetPreset) {
    return {
      ...super.getWidgetDefaultProps(widgetType, widgetPreset),
      ...(widgetType === 'HEADER' && { logoAlign: 'center' })
    };
  }

  constructor() {
    super();
    this.mappedValues = {
      ...this.mappedValues,
      backgroundColorSectionDefault: this.mappedValues.backgroundColorSection,
      backgroundColorSection() {
        const { category, section } = this.base;
        if (category === NEUTRAL && section === ALT) {
          const [primary] = utils.getDial(this, [PRIMARY]);
          return primary.setLightness(98).setAlpha(100);
        }
        return this.mappedValues.backgroundColorSectionDefault.call(this);
      },
      typographyOverrides: {
        LogoAlpha: {
          ...typographyShared1
        },
        HeadingBeta: {
          style: {
            font: 'primary',
            color: 'highlight',
            fontSize: 'xxlarge',
            fontWeight: 'normal',
            letterSpacing: 'normal',
            textTransform: 'none'
          }
        },
        HeadingGamma: {
          style: {
            font: 'alternate',
            color: 'highlight',
            fontSize: 'xlarge',
            fontWeight: 'normal',
            letterSpacing: '1px',
            textTransform: 'none'
          }
        },
        HeadingDelta: {
          style: {
            font: 'alternate',
            color: 'highContrast',
            fontSize: 'medium',
            fontWeight: 'bold',
            letterSpacing: '0.214em',
            textTransform: 'uppercase'
          }
        },
        HeadingEpsilon: {
          ...typographyShared1
        },
        ButtonAlpha: ({ size = 'default' }) => {
          const sizes = {
            small: {
              fontSize: 'xsmall'
            },
            default: {
              fontSize: 'small'
            },
            large: {
              fontSize: 'small'
            }
          };
          return {
            style: {
              font: 'alternate',
              fontWeight: 'bold',
              textTransform: 'uppercase',
              letterSpacing: '0.214em',
              ...sizes[size]
            }
          };
        },
        NavAlpha: {
          style: {
            font: 'alternate',
            color: 'highContrast',
            fontSize: 'small',
            fontWeight: 'normal',
            letterSpacing: '0.214em',
            textTransform: 'uppercase'
          }
        },
        SubNavAlpha: {
          style: {
            font: 'alternate',
            color: 'section',
            fontSize: 'small',
            fontWeight: 'normal',
            letterSpacing: 'normal',
            textTransform: 'none'
          }
        },
        NavBeta: {
          style: {
            font: 'alternate',
            color: 'highContrast',
            fontSize: 'small',
            fontWeight: 'normal',
            letterSpacing: '0.214em',
            textTransform: 'uppercase'
          }
        },
        SubNavBeta: {
          style: {
            font: 'alternate',
            color: 'section',
            fontSize: 'small',
            fontWeight: 'normal',
            letterSpacing: 'normal',
            textTransform: 'none'
          },
          active: {
            style: {
              fontWeight: 'bold',
              letterSpacing: '0.214em'
            }
          }
        }
      }
    };
  }

  /* Wrappers */
  Page(props) {
    return super.Page(
      this.merge(props, {
        fixedWidth: true,
        style: {
          'paddingHorizontal': 'xlarge',
          '@xs-only': {
            paddingHorizontal: '0px'
          },
          '> div': {
            '@md': {
              width: '100%',
              maxWidth: '1440px'
            },
            '@lg': {
              width: '100%',
              maxWidth: '1440px'
            }
          }
        }
      })
    );
  }

  get isSpecialHeadingWidget() {
    return (
      !specialHeadingExcludedLayouts.includes(this.base.widgetPreset) &&
      (specialHeadingWidgetTypes.includes(this.base.widgetType) ||
        specialHeadingWidgetLayouts.includes(this.base.widgetPreset))
    );
  }

  Section(props) {
    const isHeader = this.base.widgetType === 'HEADER';
    const isFooter = this.base.widgetType === 'FOOTER';
    const footerStyles = isFooter
      ? {
        'padding': '48px 0px 48px 0px',
        '@xs-only': { padding: '0px 32px 24px 32px' }
      }
      : {};
    const sectionSeparator = {
      'borderWidth': '3px',
      'borderColor': 'primary',
      'backgroundColor': 'section',
      'width': '100%',
      'marginBottom': 'xlarger',
      'opacity': '0.02',
      '@xs-only': {
        marginBottom: 'medium'
      }
    };
    const fullWidthWidgets = ['CONTACT', 'ORDERING', 'FOOTER'];
    const fullWidthWidgetLayouts = ['content5', 'header9'];
    const isFullWidgetLayout =
      fullWidthWidgets.includes(this.base.widgetType) ||
      fullWidthWidgetLayouts.includes(this.base.widgetPreset);
    const fullWidthWidgetStyles = isFullWidgetLayout
      ? { maxWidth: 'initial', margin: 'initial' }
      : {};
    const specialBannerWidgetLayouts = ['ordering1', 'content5'];
    const bannerWidgetStyles = specialBannerWidgetLayouts.includes(this.base.widgetType)
      ? { display: 'flex', paddingVertical: 'medium' }
      : {};
    const noBottomPaddingWidgets = ['contact1', 'contact5'];
    const isFirstPageWidget = this.base.order === 0;

    return super.Section(
      this.merge(props, {
        children:
          isHeader || isFooter ? (
            <UX2.Element.Block
              style={{
                ...fullWidthWidgetStyles,
                ...(isFooter
                  ? { backgroundColor: 'section', paddingBottom: 'small', marginTop: '-1px' }
                  : {})
              }}
            >
              { props.children }
            </UX2.Element.Block>
          ) : (
            <UX2.Element.Block style={{ ...fullWidthWidgetStyles, ...bannerWidgetStyles }}>
              { !specialBannerWidgetLayouts.includes(this.base.widgetType) && !isFirstPageWidget && (
                <UX2.Element.Block
                  category='primary'
                  style={{ backgroundColor: 'section', opacity: '0.02' }}
                >
                  <UX2.Element.HR style={ sectionSeparator } />
                </UX2.Element.Block>
              ) }
              { props.children }
            </UX2.Element.Block>
          ),
        style: {
          // contact widget doesn't need padding bottom
          'padding': isHeader
            ? '24px 0px !important'
            : noBottomPaddingWidgets.includes(this.base.widgetPreset)
              ? '72px 0px 0px 0px'
              : '40px 0px !important',
          'position': 'relative',
          'overflow':
            isHeader || specialHeadingExcludedLayouts.includes(this.base.widgetPreset)
              ? 'visible'
              : 'hidden',
          ...footerStyles,
          '@xs-only': {
            padding: isHeader
              ? '24px 8px 24px 8px !important'
              : isFooter
                ? '32px 8px 32px 8px !important'
                : '40px 24px !important'
          }
        }
      })
    );
  }

  SectionSplit(props) {
    return super.Section(
      this.merge(props, {
        style: {
          'display': 'flex',
          'alignItems': 'center',
          'paddingHorizontal': 'xlarge',
          '@xs-only': {
            paddingHorizontal: '0px'
          },
          '> div': {
            minWidth: 1 // IE11 hack
          }
        }
      })
    );
  }

  SectionBanner(props) {
    const specialBannerWidgetLayouts = ['content5', 'ordering1'];
    return super.SectionBanner(
      this.merge(props, {
        style: {
          'position': 'relative',
          'overflow': specialHeadingExcludedLayouts.includes(this.base.widgetPreset)
            ? 'visible'
            : 'hidden',
          '@md': {
            padding:
              specialBannerWidgetLayouts.indexOf(this.base.widgetPreset) >= 0
                ? '40px 0px !important'
                : '72px 40px'
          },
          '@xs': {
            padding:
              specialBannerWidgetLayouts.indexOf(this.base.widgetPreset) >= 0
                ? '40px 0px !important'
                : '24px 0px'
          }
        }
      })
    );
  }

  /* Hero */
  Hero(props) {
    return super.Hero(
      this.merge(props, {
        style: {
          textAlign: 'center',
          ['@md']: {
            maxWidth: '100%'
          },
          ...omit(props.style, ['> :nth-child(n)', ' > :last-child'])
        }
      })
    );
  }

  HeroText(props) {
    return this.SubTagline(
      this.merge(props, {
        style: {
          overflowWrap: 'break-word',
          wordWrap: 'break-word',
          maxWidth: '100%',
          display: 'inline-block'
        }
      })
    );
  }

  Tagline(props) {
    return super.Tagline(
      this.merge(
        {
          style: {
            position: 'relative',
            marginHorizontal: 'auto',
            overflowWrap: 'break-word',
            wordWrap: 'break-word',
            maxWidth: '100%',
            color: 'inherit'
          },
          typography: 'HeadingDelta'
        },
        props
      )
    );
  }

  /* Heading */
  Heading(props) {
    const { tag, layout = 'full' } = props;
    const { widgetType, widgetPreset } = this.base;
    const menuOverrideStyles = getMenuHeadingStyle(tag, widgetType, widgetPreset);
    const splitSectionWidgets = ['contact9'];
    const topPositionedWidgets = ['contact1', 'contact2', 'contact3', 'contact5'];
    // alternative H2 position when 50% typically places H2 in a semi-covered position
    const h2TopPlacement =
      topPositionedWidgets.indexOf(this.base.widgetPreset) >= 0 ? { top: '250px' } : {};
    const isSplitSectionWidget = splitSectionWidgets.indexOf(this.base.widgetPreset) >= 0;
    const centeredH4WidgetLayouts = ['contact1', 'contact2', 'contact3', 'contact4', 'contact5'];
    const headingStyles = {
      h1: {
        textIndent: '1px'
      },
      h2: {
        'textIndent': '8px',
        'position': 'absolute !important',
        'top': '50%',
        'left': '1%',
        'transform': 'translateY(-50%) rotate(-10deg)',
        'width': isSplitSectionWidget && layout === 'half' ? '50%' : '100%',
        'justifyContent': 'center',
        'display': 'flex',
        'whiteSpace': 'pre-wrap',
        'textAlign': 'center',
        ...h2TopPlacement,
        '@xs-only': {
          top: '200px',
          left: '-2%',
          width: '100%'
        },
        '@sm-only': {
          top: '250px',
          width: '100%'
        }
      },
      h3: {
        textIndent: '1px'
      },
      h4: {
        'textIndent': '2px',
        '@xs-only': {
          margin:
            centeredH4WidgetLayouts.indexOf(this.base.widgetPreset) >= 0
              ? '0px auto 24px auto !important'
              : ''
        }
      }
    };
    const level = props.tag || `h${Number(props.level)}`;
    const style = level === 'h2' && !this.isSpecialHeadingWidget ? {} : headingStyles[level] || {};
    const updatedProps = props;
    if (level === 'h2' && this.isSpecialHeadingWidget) {
      // Preserve data-route as data-field so SEV can still find it, but remove data-route so editor will ignore
      updatedProps['data-field'] = updatedProps['data-field'] || updatedProps['data-route'];
      delete updatedProps['data-route'];
    }
    const typography = {
      5: 'DetailsAlpha'
    }[levelFilter({ tag: props.tag, level: props.level })];
    return super.Heading(
      this.merge({ style, typography }, { style: menuOverrideStyles }, updatedProps)
    );
  }

  SectionHeading({ children = '', richtext, ...props }) {
    const charCount = children.length;
    const fontSizeMap = {
      '220px': [0, 10],
      '150px': [10, 25],
      '100px': [25]
    };
    const fontSizeMapMobile = {
      '150px': [0, 5],
      '100px': [5, 10],
      '75px': [10, 20],
      '50px': [20]
    };
    const mobileLineFontSize = resolveFontSizeCharCount({
      count: charCount,
      fontSizeMap: fontSizeMapMobile,
      defaultFontSize: '150px'
    });
    const desktopLineFontSize = resolveFontSizeCharCount({
      count: charCount,
      fontSizeMap,
      defaultFontSize: '220px'
    });
    const childStyles = this.isSpecialHeadingWidget
      ? {
        'display': 'inline-block',
        'opacity': '0.08',
        'lineHeight': desktopLineFontSize,
        'fontSize': desktopLineFontSize,
        'wordWrap': 'normal',
        'overflowWrap': 'normal',
        '@xs-only': {
          textIndent: '6px',
          lineHeight: mobileLineFontSize,
          fontSize: mobileLineFontSize
        }
      }
      : {};
    const style = this.isSpecialHeadingWidget
      ? {
        overflow: 'visible',
        textAlign: 'center',
        position: 'absolute !important',
        pointerEvents: 'none'
      }
      : {
        overflow: this.isForcedBannerWidget ? 'visible' : 'hidden',
        textAlign: 'center'
      };
    if (this.isSpecialHeadingWidget) {
      // Preserve data-route as data-field so SEV can still find it, but remove data-route so editor will ignore
      props['data-field'] = props['data-field'] || props['data-route'];
      delete props['data-route'];
    }
    return super.SectionHeading(
      this.merge(props, {
        children: (
          <UX2.Element.Element
            richtext={ richtext }
            style={{ textTransform: 'unset', letterSpacing: 'unset', ...childStyles }}
          >
            { children }
          </UX2.Element.Element>
        ),
        style
      })
    );
  }

  SectionHeadingHR(props) {
    return super.HR(this.merge(props, { style: { display: 'none' } }));
  }

  /* Content */
  Content(props) {
    // about3 Content blocks need to be wrapped and reorientated due to a theme specific width change
    const mergeObj =
      this.base.widgetPreset === 'about3'
        ? {
          children: (
            <UX2.Element.Block style={{ display: 'inline-block' }}>
              { props.children }
            </UX2.Element.Block>
          ),
          style: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }
        }
        : {};
    return this.merge(props, mergeObj);
  }

  ContentBasic(props) {
    const shrunkWidthWidgets = ['about2'];
    const marginModifiedWidgets = ['about1'];
    const marginStyles =
      marginModifiedWidgets.indexOf(this.base.widgetPreset) >= 0 ? { marginBottom: 'medium' } : {};
    const widthStyles =
      shrunkWidthWidgets.indexOf(this.base.widgetPreset) >= 0
        ? { '@md': { width: '70%', margin: '0 auto' } }
        : {};
    return super.ContentBasic(
      this.merge(props, {
        style: {
          ...marginStyles,
          ...widthStyles
        }
      })
    );
  }

  ContentText(props) {
    const style = {
      'lineHeight': '32px',
      ' > p': {
        lineHeight: '32px'
      }
    };
    return super.ContentText(this.merge(props, { style }));
  }

  ContentHeading(props) {
    const headingStyles = {
      textIndent: '1px',
      borderBottom: 'none',
      minWidth: '100%'
    };
    const style = Number(props.level) !== 2 && props.tag !== 'h2' ? headingStyles : {};
    return super.ContentHeading(this.merge(props, { style }));
  }

  ContentOverlayCardHeading(props) {
    return super.Heading(
      this.merge(
        {
          level: 4
        },
        props
      )
    );
  }

  FeaturedHeading(props) {
    return super.FeaturedHeading(
      this.merge(props, {
        style: {
          paddingBottom: 'medium'
        },
        typography: 'HeadingEpsilon'
      })
    );
  }

  FeaturedText(props) {
    return super.FeaturedText(
      this.merge(props, {
        style: {
          '> p': {
            paddingBottom: 'medium'
          },
          '> p > a': {
            paddingBottom: 'medium'
          }
        }
      })
    );
  }

  /* Base Components */
  Image(props) {
    const widgetsWithFullImage = ['about1'];
    const themeStyleOverrides = {
      borderRadius: '0px !important',
      position: 'relative'
    };
    const fullImageStyles =
      widgetsWithFullImage.indexOf(this.base.widgetPreset) >= 0
        ? {
          width: '100%',
          height: 'auto'
        }
        : {};
    return super.Image(
      this.merge(props, {
        mobileGutterWidth: 48,
        style: { ...themeStyleOverrides, ...fullImageStyles }
      })
    );
  }

  Icon(props) {
    let { icon } = props;
    icon = minimalIconMapping[icon] || icon;
    return super.Icon(
      this.merge(props, {
        icon,
        iconPack: socialIconPack
      })
    );
  }

  IconSearch(props) {
    return super.IconSearch(
      this.merge(
        {
          style: {
            '@xs-only': {
              color: 'primary'
            },
            '@sm-only': {
              color: 'primary'
            }
          }
        },
        props
      )
    );
  }

  Loader(props) {
    return Ripple.apply(this, [props]);
  }

  Divider(props) {
    const footerDividerStyles = this.base.widgetType === 'FOOTER' ? { display: 'none' } : {};
    return super.Divider(this.merge(props, { style: footerDividerStyles }));
  }

  DividerHR(props) {
    const footerDividerStyles = this.base.widgetType === 'FOOTER' ? { display: 'none' } : {};
    return super.DividerHR(
      this.merge(props, {
        style: {
          height: '1px',
          borderColor: '! #999',
          ...footerDividerStyles
        }
      })
    );
  }

  Input(props) {
    const isOlsSearchField = props['data-aid'] === 'SEARCH_FIELD_RENDERED';
    const primaryColorInput = isOlsSearchField
      ? {
        '::placeholder': { color: 'inputPlaceholder' },
        'borderColor': 'primary'
      }
      : {};
    return super.Input(
      this.merge(
        {
          style: {
            'paddingVertical': 'small',
            'paddingHorizontal': 'small',
            'borderStyle': 'solid',
            'borderWidth': 'xsmall',
            'borderTopWidth': 0,
            'borderLeftWidth': 0,
            'borderRightWidth': 0,
            'backgroundColor': 'transparent',
            ...primaryColorInput,
            '@sm-only': {
              marginBottom: isOlsSearchField ? 0 : 'medium'
            },
            '@xs-only': {
              marginBottom: isOlsSearchField ? 0 : 'medium'
            }
          }
        },
        props
      )
    );
  }

  InputTextArea(props) {
    return super.InputTextArea(
      this.merge(
        {
          style: {
            borderTopWidth: 1,
            borderLeftWidth: 1,
            borderRightWidth: 1
          }
        },
        props
      )
    );
  }

  InputFloatLabelLabel(props) {
    return super.InputFloatLabelLabel(
      this.merge(props, {
        style: {
          left: '16px',
          top: '33%'
        }
      })
    );
  }

  InputSearch(props) {
    return super.InputSearch(
      this.merge(
        {
          style: {
            backgroundColor: 'section',
            borderRadius: 0
          }
        },
        props
      )
    );
  }

  /* NavigationDrawer */
  NavigationDrawer(props) {
    return super.NavigationDrawer(
      this.merge(props, {
        category: 'neutral',
        style: {
          paddingTop: '100px',
          backgroundColor: 'section'
        }
      })
    );
  }

  NavigationDrawerInputSearch(props) {
    return super.NavigationDrawerInputSearch(
      this.merge(
        {
          style: {
            backgroundColor: 'searchMobileLight'
          }
        },
        props
      )
    );
  }

  NavigationDrawerListItem(props) {
    return super.NavigationDrawerListItem(
      this.merge(
        {
          style: {
            borderBottomWidth: 0
          }
        },
        props
      )
    );
  }

  NavigationDrawerLink(props) {
    return super.NavigationDrawerLink(
      this.merge(
        {
          style: {
            textIndent: '4px',
            paddingLeft: 'xxlarge',
            paddingVertical: 'small'
          }
        },
        props
      )
    );
  }

  NavigationDrawerSubLink(props) {
    return super.NavigationDrawerSubLink(
      this.merge(
        {
          style: {
            paddingLeft: 'xxxlarge'
          }
        },
        props
      )
    );
  }

  MembershipHeading(props) {
    return super.MembershipHeading(
      this.merge(
        {
          style: {
            paddingHorizontal: 'xxlarge',
            textIndent: '4px'
          }
        },
        props
      )
    );
  }

  /* Split Container */
  ContainerSplit(props) {
    const widgetsWithoutPadding = ['contact4'];
    const paddingStyles =
      widgetsWithoutPadding.indexOf(this.base.widgetPreset) >= 0 ? { padding: '0' } : {};
    const styles = {
      'paddingHorizontal': 0,
      'display': 'flex',
      'flexDirection': 'column',
      'minHeight': 'inherit',
      '@md': {
        flexWrap: 'nowrap',
        flexDirection: 'row'
      },
      '@xs-only': {
        paddingVertical: 'xlarge',
        paddingHorizontal: 'medium'
      },
      ...paddingStyles
    };
    return this.ContainerFluid(
      this.merge(props, {
        children: <UX2.Element.Block style={ styles }> { props.children } </UX2.Element.Block>
      })
    );
  }

  SplitItem(props) {
    return super.SplitItem(
      this.merge(
        {
          style: {
            '@md': {
              paddingVertical: 'large'
            }
          }
        },
        props
      )
    );
  }

  SplitItemImage(props) {
    // does not guarantee a square, but it will guarantee enough room for the adjacent splitImage content
    const fixedHeight = this.base.widgetPreset === 'about5' ? { minHeight: '680px' } : {};
    return super.SplitItemImage(
      this.merge(
        {
          style: {
            '@md': {
              maxWidth: '50%',
              ...fixedHeight
            }
          }
        },
        props
      )
    );
  }

  /* Card Banner */
  CardBanner(props) {
    const { heading, headingProps = {}, action } = props;
    const cardDesktopWidth = '405px';
    const cardMobileWidth = '296px';
    const styles = {
      container: {
        'display': 'flex',
        'justifyContent': 'center',
        'alignItems': 'flex-end',
        'textShadow': 'none',
        'height': '300px',
        'width': cardDesktopWidth,
        'marginLeft': 'auto',
        'marginRight': 'medium',
        '@md': {
          flexDirection: 'column'
        },
        '@sm-only': {
          alignItems: 'center'
        },
        '@xs-only': {
          height: '280px',
          width: cardMobileWidth,
          marginRight: 'auto',
          alignItems: 'center'
        }
      },
      childrenBackgroundOverlay: {
        'height': '300px',
        'width': cardDesktopWidth,
        'position': 'absolute',
        'backgroundColor': 'section',
        'opacity': '0.5',
        '@xs-only': {
          margin: '0 auto',
          height: '280px',
          width: cardMobileWidth
        }
      },
      childrenContainer: {
        'zIndex': '1',
        'maxWidth': cardDesktopWidth,
        'paddingHorizontal': 'large',
        'flexDirection': 'column',
        'flexGrow': '0',
        '@xs-only': {
          paddingHorizontal: '0px',
          maxWidth: cardMobileWidth
        }
      },
      headingStyles: {
        '@md': { textAlign: 'left' },
        'marginBottom': action ? 'medium' : {}
      }
    };
    return this.Container(
      this.merge(props, {
        group: 'Card',
        groupType: 'Banner',
        children: (
          <React.Fragment>
            { (heading || action) && (
              <UX2.Element.Block
                category={ this.base.category === 'primary' ? 'accent' : 'primary' }
                style={ styles.childrenBackgroundOverlay }
              >
                <UX2.Element.Block style={ styles.childrenBackgroundOverlay } />
              </UX2.Element.Block>
            ) }
            <UX2.Element.Block style={ styles.childrenContainer }>
              { heading && (
                <UX2.Element.Block>
                  <UX2.Element.Heading { ...headingProps } style={ styles.headingStyles }>
                    { heading }
                  </UX2.Element.Heading>
                </UX2.Element.Block>
              ) }
              { action && <UX2.Element.Block category='primary'>{ action }</UX2.Element.Block> }
            </UX2.Element.Block>
          </React.Fragment>
        ),
        style: styles.container
      })
    );
  }

  /* Link */
  SocialLinks(props) {
    return this.merge(props, {
      style: {
        'display': 'flex',
        'justifyContent': 'center',
        '> *': {
          paddingVertical: '0px !important'
        },
        '@md': {
          justifyContent: 'flex-start'
        }
      }
    });
  }

  NavLink(props) {
    return super.NavLink(
      this.merge(
        {
          style: {
            textIndent: '4px'
          }
        },
        props
      )
    );
  }

  PromoBannerText(props) {
    return super.PromoBannerText(
      this.merge(
        {
          typography: 'HeadingDelta',
          style: {
            '@xs': {
              paddingHorizontal: 'xxsmall'
            },
            '@md': {
              paddingHorizontal: 'xsmall'
            }
          }
        },
        props
      )
    );
  }

  UtilitiesMenu(props) {
    return super.UtilitiesMenu(
      this.merge(
        {
          style: {
            '@md': {
              paddingRight: 'xsmall'
            }
          }
        },
        props
      )
    );
  }

  HeroLeft(props) {
    return this.Hero(
      this.merge(
        {
          style: {
            ['@sm']: {
              borderColor: 'rgba(255, 255, 255, 0.5)',
              borderLeftStyle: 'solid',
              borderLeftWidth: '1px',
              paddingLeft: 'large'
            },
            border: 'none',
            paddingLeft: 'none'
          }
        },
        props
      )
    );
  }

  HeroRight(props) {
    return this.Hero(
      this.merge(
        {
          style: {
            ['@sm']: {
              borderColor: 'rgba(255, 255, 255, 0.5)',
              borderRightStyle: 'solid',
              borderRightWidth: '1px',
              paddingRight: 'large'
            },
            border: 'none',
            paddingRight: 0
          }
        },
        props
      )
    );
  }

  HeroCenterHeading(props) {
    return this.HeroHeading(
      this.merge(
        {
          style: {
            borderBottomStyle: 'solid',
            borderBottomWidth: '1px',
            borderColor: 'rgba(255, 255, 255, 0.5)',
            paddingBottom: 'small'
          }
        },
        props
      )
    );
  }

  Table(props) {
    return super.Table(
      this.merge(
        {
          style: {
            borderRadius: 0
          }
        },
        props
      )
    );
  }

  InputFloatLabelInput(props) {
    return super.InputFloatLabelInput(
      this.merge(
        {
          style: {
            paddingBottom: 'xsmall'
          }
        },
        props
      )
    );
  }

  InputSelect(props) {
    return super.InputSelect(
      this.merge(
        {
          style: {
            borderWidth: 'xsmall',
            borderRadius: 0,
            borderStyle: 'solid',
            ['@xs-only']: {
              fontSize: 'medium' // 16px
            }
          }
        },
        props
      )
    );
  }

  InputSelectElement(props) {
    return super.InputSelectElement(
      this.merge(
        {
          style: {
            padding: 'small',
            ['@xs-only']: {
              fontSize: 'medium' // 16px
            }
          }
        },
        props
      )
    );
  }

  MediaObjectBackground(props) {
    return super.MediaObjectBackground(
      this.merge(
        {
          style: {
            borderRadius: '0'
          }
        },
        props
      )
    );
  }

  Intro(props) {
    return super.Intro(this.merge({ alignment: 'center' }, props));
  }

  SubTagline(props) {
    return super.SubTagline(this.merge({ typography: 'HeadingGamma' }, props));
  }

  CardBannerHeading(props) {
    return super.CardBannerHeading(
      this.merge(
        {
          typography: 'HeadingGamma',
          fontSizeMap: {
            xlarge: [0, 40],
            large: [40, 80],
            medium: [80]
          }
        },
        props
      )
    );
  }

  NavMenuLink(props) {
    return super.NavMenuLink(this.merge({ typography: 'NavAlpha' }, props));
  }

  LogoHeading(props) {
    const { style = {} } = props;
    const hasLogoFontSelected = style.font === 'logo';
    const defaultLogoFontStyles = {
      borderBottom: '1.5px solid',
      borderColor: 'primary',
      paddingBottom: 'xsmall'
    };
    return super.LogoHeading(
      this.merge(
        {
          style: {
            textAlign: 'center',
            maxWidth: '100%',
            width: 'fit-content',
            margin: '0 auto',
            ['@md']: {
              marginBottom: '0px'
            },
            ...(hasLogoFontSelected ? {} : defaultLogoFontStyles)
          }
        },
        props
      )
    );
  }

  Button(props) {
    return super.Button(this.merge({ customBorderWidth: 'small' }, props));
  }

  WrapperVideo({ alignmentOption, videoHeight, videoWidth, ...props }) {
    const borderStyles = {
      borderStyle: 'solid',
      borderWidth: 20,
      backgroundColor: 'section',
      borderColor: 'white',
      boxShadow: '0 2px 6px 4px rgba(0,0,0,0.1)'
    };
    let wrapperWidth = '100%';
    if (alignmentOption === 'center' && videoHeight > videoWidth) {
      const aspectRatio = videoWidth / videoHeight;
      const newHeight = clamp(videoHeight, 0, 680);
      wrapperWidth = aspectRatio * newHeight;
    }
    return super.Wrapper(
      this.merge(
        { style: { ...borderStyles, 'width': wrapperWidth, '@xs-only': { width: '100%' } } },
        props
      )
    );
  }

  EmbedVideo({ isVerticalVideo, alignmentOption, ...props }) {
    if (isVerticalVideo && alignmentOption === 'center') {
      return super.EmbedVideo(this.merge({ style: { maxHeight: '680px' } }, props));
    }
    return super.EmbedVideo(props);
  }

  WrapperInsetEmbedVideo({ isVerticalVideo, alignmentOption, ...props }) {
    if (isVerticalVideo && alignmentOption === 'center') {
      return super.WrapperInsetEmbedVideo(this.merge({ style: { maxHeight: '680px' } }, props));
    }
    return super.WrapperInsetEmbedVideo(props);
  }
}

export default Theme23;
